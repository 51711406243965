/********************************************************************/
/************************-Top Header-********************************/
/********************************************************************/

.top-header {
    background: #fff;
    color: #000;
    padding: 10px;
    font-size: 14px;
}

.top-header .timeclock {
    padding-right: 10px;
    width: 30px;
    height: 20px;
}

.top-header .social-icon {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 15px;
    transition: 0.7s ease;
}

.top-header a {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #000;
}

.top-header svg:hover path {
    fill: #fff000;
}

.main-header {
    position: absolute;
    width: 100%;
    z-index: 9;
}

/********************************************************************/
/****************************-Social Icons Hover-********************/
/********************************************************************/

.social-icon svg:hover {
    scale: 1.1;
    transition: 0.5s;
}

.insta:hover,
.facebook:hover,
.linkedin:hover,
.twitter:hover {
    background: #fff;
    border-radius: 100%;
    transition: 0.5s linear;
}

.insta,
.facebook,
.linkedin,
.twitter {
    padding: 5px;
}

.insta:hover svg path {
    fill: #b200ed;
}

.facebook:hover svg path {
    fill: blue;
}

.linkedin:hover svg path {
    fill: darkblue;
}

.twitter:hover svg path {
    fill: rgb(20, 183, 247);
}

.top-header .social-icon img {
    width: 15px;
    height: 15px;
}

.top-header .info {
    display: flex;
    justify-content: end;
    gap: 10px;
}

.top-header .info svg {
    padding-right: 5px;
}


/********************************************************************/
/*******************************-Main Header-************************/
/********************************************************************/


.main-header {
    padding: 10px 0;
}

.logo {
    width: 10vw;
}

.menu {
    background: #ffffff70;
    display: flex;
    gap: 25px;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 40px;
    border: 1px solid #fff;
}

.menu button {
    background-color: #fff;
    border-radius: 40px;
    padding: 10px 30px;
    border: 1px solid #000;
}

.menu button:hover {
    background-color: #FCC93A;
    border: 1px solid #fcc93a;
    box-shadow: 0 0 10px #00000030;
}

.menu a {
    color: #000;
    text-decoration: none;
    padding: 10px 0;
    font-size: 16px;
}

.menu a:hover,
.menu span:hover {
    color: #000;
}

.menu a:hover::after,
.menu span:hover::after {
    visibility: visible;
}

.menu .menuList::before {
    content: '/';
    position: relative;
    left: -15px;
}

.menu li:nth-child(1):before {
    content: '';
}

.menu a::after,
.menu span::after {
    content: '';
    position: relative;
    display: block;
    width: 100%;
    height: 1.5px;
    background-color: #000;
    visibility: hidden;
}

.menu .openMenu {
    cursor: pointer;
    padding: 10px 0;
    font-size: 17px;
}

.menu .openMenu:hover .megaMenu {
    display: flex !important;
}

.megaMenu {
    position: absolute;
    background: transparent;
    right: 5%;
    top: 60%;
    width: 690px;
    z-index: 999;
    display: none !important;
    animation: slideDown 0.5s ease;
    padding: 15px;
}

.megamenu-inner {
    background: #fff0c1;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000022;
    width: 100%;
}

.megaMenu a {
    padding: 0px 0 !important;
    font-size: 15px !important;
}

.megaMenu a::after,
.megaMenu a::before {
    display: none;
}

.megaMenu .abtintro {
    padding: 0;
    background-color: transparent;
    padding: 10px 40px;
}

.megaMenu .abtintro li {
    display: grid;
    grid-template-columns: auto;
    gap: 0;
    padding: 10px 0;
}

.megaMenu .abtintro span {
    padding: 0;
}

@keyframes slideDown {
    0% {
        opacity: 0;

    }

    100% {
        opacity: 1;
    }
}

a {
    text-decoration: none;
}

a:hover {
    color: #818181;
}

a:hover svg {
    scale: 1.2;
    transition: 0.4s;
}

.megaMenu img {
    width: 40%;
    height: 400px;
    object-fit: cover;
}

.megaMenu ul {
    list-style: none;
    padding: 15px 10px;
}

.megaMenu ul li img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    margin-right: 20px;
}


.megaMenu ul li {
    padding: 15px 0;
    font-size: 16px !important;
    font-weight: 600;
    cursor: pointer;
    color: #000;
    display: flex;
    align-items: center;
}

.megaMenu ul li:hover {
    color: #000;
}

.megaMenu ul li:hover img {
    scale: 1.1;
    transition: 0.35s;
}

.quotesbutton {
    padding: 15px 22px;
    background-color: transparent;
    color: #000;
    float: right;
    text-transform: uppercase;
    font-size: 18px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.quotesbutton::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    background-color: #c5edd3;
    width: 0%;
    height: 100%;
    z-index: -1;
    animation: buttonAnimate reverse;
    transition: all 0.7s;
}

.quotesbutton:hover {
    color: #158842;
    border: 1px solid #158842;
    box-shadow: 0 10px 10px #00000023;
    margin-top: -10px;
    transition: all 0.5s;
}

.quotesbutton:hover::after {
    width: 100%;
    /* animation: buttonAnimate 0.4s linear ; */

}

@keyframes buttonAnimate {
    0% {
        top: -100%;
    }

    100% {
        top: 0%;
    }
}


.main-header-mobile {
    display: none;
}

@media (max-width: 767px) {

    .top-header .social-icon,
    .top-header .timing {
        display: none !important;
    }

    .top-header {
        padding: 10px 0;
        display: none;
    }

    .top-header .info {
        justify-content: start;
    }

    .top-header .info a {
        font-size: 12px;
    }

    .main-header {
        display: none;
    }

    .main-header-mobile {
        display: block;
    }

    .main-header-mobile {
        padding: 10px 0px;
    }

    .main-header-mobile .logo {
        width: 60px;
    }

    .main-header-mobile .svg {
        margin-right: 20px;
    }

    .mobilemenu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100vw;
        height: 100vh;
        color: #000;
        background-color: #fff;
        padding: 20px;
        display: none;
    }

    .mobilemenu ul {
        padding: 0;
        list-style: none;
    }

    .mobilemenu li {
        padding-bottom: 20px;
    }

    .mobilemenu a {
        color: #000;
        font-size: 17px;
        padding-bottom: 20px;
    }

    .mobilemenu svg {
        position: absolute;
        top: 20px;
        right: 20px;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    /*     
    .top-header {
        display: none!important;
    } */

    .logo {
        width: 14vw !important;
    }


    .main-header {
        display: none;
    }

    .main-header-mobile {
        display: block;
    }

    .main-header-mobile {
        padding: 10px 0px;
    }

    .main-header-mobile .logo {
        width: 60px;
    }

    .main-header-mobile .svg {
        margin-right: 20px;
    }

    .mobilemenu {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        width: 100vw;
        height: 100vh;
        color: #000;
        background-color: #fff;
        padding: 20px;
        display: none;
    }

    .mobilemenu ul {
        padding: 0;
        list-style: none;
    }

    .mobilemenu li {
        padding-bottom: 20px;
    }

    .mobilemenu a {
        color: #000;
        font-size: 17px;
        padding-bottom: 20px;
    }

    .mobilemenu svg {
        position: absolute;
        top: 20px;
        right: 20px;
    }


    .top-header .info {
        display: grid;
    }

    .main-header {
        display: none;
    }

    .main-header-mobile {
        display: block;
    }

    .hero-slider .whitebox {
        width: 100% !important;
    }

    .hero-slider .whitebox h2 {
        position: relative;
        font-size: 41px !important;
    }

    .hero-slider .row {
        padding: 0 70px !important;
    }

    .checklist {
        padding: 35px 0 !important;
    }

    .checklist-wrapper {
        display: grid !important;
        gap: 10%;
        row-gap: 20px !important;
        grid-template-columns: auto auto;
    }

    .process-section .process-wrapper {
        height: 325px !important;
        border-radius: 15px !important;
    }

    .process-section .title h3 {
        font-size: 32px !important;
    }

    .process-wrapper h3 {
        font-size: 22px !important;
    }

    .process-section p {
        font-size: 13px !important
    }
}