.servicebanner{
    background:url('../Assets/img/servicebg.png');
    background-size: cover;
}

.servicebanner  h2{
    
    font-size:45px;
    font-weight: bold;
    padding-bottom:20px;
}

.servicebanner p{
    text-transform: capitalize!important;
    font-size:18px!important;
}

.how-we-work .title{
    padding-bottom: 50px;
}

.how-we-work h3{
    padding-bottom: 25px;
}

#ourwork{
    width:80%;
}

.detail-bx{
    background-color: #FEF5CC;
    padding:55px 30px 25px;
    border-radius: 40px;
    margin:70px 0;
    position: relative;
    box-shadow: 0 5px 20px #00000030;
    width:400px;
}

.detail-bx h2{
    background: #FEF5CC;
    position: absolute;
    width:70px;
    height:70px;
    top:-30px;
    padding:10px;
    border-radius: 20px;
    font-size:40px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 5px 10px #00000030;
}

.detail-bx h3{
    font-weight: bold;
    font-size: 24px;
    padding-bottom:15px;
}

.how-we-work .left-box-wrapper .detail-bx {
    right:-25px;
}

.how-we-work .right-box-wrapper .detail-bx {
    left:-115px;
}

.tab-contain{
    padding:25px 40px;
    background-color: #efefef;
    border-radius: 15px;
}

.tab-contain .tab{
    font-size:18px;
    padding:15px 0;
    position: relative;
    cursor:pointer;
}

.tab-contain .tab svg{
    position: absolute;
    right:0;
}
.tab-contain .tab:hover{
    color:#158842;
}
.tab-contain .tab:hover svg{
    right:-5px;
    transition: all 0.75s;
}


.tab-content img{
    width:100%;
    min-height:500px;
    max-height:500px;
    border-radius: 10px;
    margin-bottom:20px;
}


.tab-content h2{
    font-size:50px;
}

.tab-content h3{
    font-size: 30px;
    padding-bottom: 10px;
}

.tab-content h3 span{
    color:#158842;
}

.tab-content b, ol li{
    font-size:18px;
}

.tab-content b{
    font-weight:700;
}

.tab-content ol{
    padding:20px;
}

.tab-contain ol li::marker{
    font-weight: bold!important;
}

.tab-content ol li{
    padding-bottom: 10px;
}
/* .tab-contain .tab:hover{
    background-color: #fff ;
} */

.greenbox{
    margin-top:50px;
    background-color: #158842;
    padding:50px 50px 150px;
    text-align: center;
    color:#fff;
    border-radius: 15px;
}

.greenbox button{
    background: transparent;
    color:#fff;
    border:1px solid #fff;
    padding:15px 25px;
    margin-top:40px;
    width:50%;
}

.greenbox-visible-mobile{
    display: none;
}

.our-service-section{
    padding:100px 0;
}

.our-service-section .title{
    text-align: center;
}

.our-service-section .title h3{
    font-size:35px;
    font-weight:bold;
}

.bgyellow{
    background-color: #FEF5CC;
}

.service-detail{
    padding:100px 0;
}

.service-detail h3{
    font-weight:bold;
    padding-bottom:20px;
}

.service-detail ul{
    list-style-image: url('../Assets/img/check.png');
    padding-left:20px;
}

.desktop-vissible{
    display: block;
}
.mobile-vissible{
    display: none;
}

.service-detail img{
    width:100%;
}

@media (min-width: 480px) and (max-width:767px) {
    #ourwork{
        display: none!important;
    }   
    .mobile-vissible .left-box-wrapper div:first-child{
        display: grid;
        grid-template-columns: auto auto;
        gap:20px;
    } 
    .detail-bx{
        margin:35px 0;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .servicetab-section{
        padding:50px 10px!important;
    }

    .greenbox{
        display: none;
    }

    .tab-content img{
        margin:20px 0;
        min-height: 250px;
        max-height: 250px;
        object-fit: cover;
    }

    .greenbox-visible-mobile{
        display: block;
        padding:40px 20px;
    }

    .detail-bx{
        width:100%;
        right:0!important;
    }

    .how-we-work .title{
        padding-bottom:20px!important;
    }
    
    .how-we-work .right-box-wrapper .detail-bx{
        left:0;
    }

    #ourwork{
        width:100%!important;
    }

    .desktop-vissible{
        display: none;
    }
    .mobile-vissible{
        display: block;
    }

    .our-service-section{
        padding:50px 15px!important;
    }

    .service-detail{
        padding:50px 15px;
    }

    .service-detail img{
        width:100%;
        margin:20px 0;
    }   

}
