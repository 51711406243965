.servicebanner {
    padding: 120px 0;
    text-align: center;
    height: auto !important;
}

.tab-contain {
    padding: 25px 40px;
    background-color: #efefef;
    border-radius: 15px;
}

.tab-contain .tab {
    font-size: 18px;
    padding: 15px 0;
    position: relative;
    cursor: pointer;
}

.tab-contain .tab svg {
    position: absolute;
    right: 0;
}

.tab-contain .tab:hover {
    color: #158842;
}

.tab-contain .tab:hover svg {
    right: -5px;
    transition: all 0.75s;
}


.tab-content img {
    object-fit: cover;
    width: 100%;
    min-height: 500px;
    max-height: 500px;
    border-radius: 10px;
    margin-bottom: 20px;
}


.tab-content h2 {
    font-size: 50px;
}

.tab-content h3 {
    font-size: 30px;
    padding-bottom: 10px;
}

.tab-content h3 span {
    color: #fef5cc;

}

.tab-content b,
ol li {
    font-size: 18px;
}

.tab-content b {
    font-weight: 700;
}

.tab-content ol {
    padding: 20px;
}

.tab-contain ol li::marker {
    font-weight: bold !important;
}

.tab-content ol li {
    padding-bottom: 10px;
}

.tab-contain .tab a {
    color: #000 !important;
}

/* .tab-contain .tab:hover{
    background-color: #fff ;
} */
.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin: 20px 0;
}

.card {
    display: flex;
    height: 100%;
    padding: 60px 30px;
    border-radius: 40px;
    background: #ffffff;
    transition: background 0.3s ease;
    cursor: pointer;
    /* Smooth transition for background change */
}

.card h3 {
    font-weight: 500;
}




.greenbox {
    margin-top: 50px;
    background-color: #fef5cc;
    padding: 50px 50px 150px;
    text-align: center;
    color: black;
    border-radius: 15px;
}

.booknow {
    background: #fff;
    border: none;
    padding: 12px 50px;
    border-radius: 30px;
    border: 1px solid black;
    /* width: 50%; */
    color: black;
}

.booknow:hover {
    background-color: black;
    color: white;
}

.greenbox button {
    background: #fff;
    border: none;
    padding: 12px 0;
    border-radius: 30px;
    width: 50%;
    color: black;
    /* border: 1px solid black;
    padding: 15px 25px;
    margin-top: 40px;*/

}

.greenbox button:hover {
    background-color: black;
    color: white;
}

.greenbox-visible-mobile {
    display: none;
}

/****TABLET****/
@media (min-width: 481px) and (max-width: 1023px) {

    .tab-content h2 {
        font-size: 40px !important;
    }

    .card {
        padding: 40px 20px;
        margin: 0 0;
    }

    .card h3 {
        font-size: 20px;
    }

    .card p {
        font-size: 16px;
    }

    .servicetab-section {
        padding: 50px 10px !important;
    }

    .servicetab-section .tab-contain {
        padding: 25px 20px;
    }

    .tab-contain img {
        min-height: 380px;
        max-height: 380px;
    }

    .greenbox {
        padding: 20px 15px;
    }

    .greenbox h3 {
        font-size: 35px;
    }

    .greenbox button {
        margin-top: 15px;
        width: 50%;
    }

    .greenbox {
        display: none;
    }

    .greenbox-visible-mobile {
        display: block;
        padding: 40px 20px;
    }
}


@media (max-width: 480px) {
    .card {
        padding: 40px 20px !important;
        margin: 0 0;
    }

    .card h3 {
        font-size: 20px;
    }

    .card p {
        font-size: 16px;
    }

    .servicetab-section {
        padding: 50px 10px !important;
    }

    .greenbox {
        display: none;
    }

    .tab-content img {
        margin: 20px 0;
        min-height: 250px;
        max-height: 250px;
        object-fit: cover;
    }

    .greenbox-visible-mobile {
        display: block;
        padding: 40px 20px;
    }

    .tab-content h2 {
        font-size: 30px !important;
    }


}